.field-icon {
  float: left;
  margin-left: -40px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
input[type="file"]{
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}
