input[type="date"]::before { 
	content: attr(data-placeholder);
	width: 100%;
}

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
input[type="date"]:focus::before,
input[type="date"]:valid::before { display: none }

.foo{
  padding-left: 50px;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
  opacity: 100%;
  /* background-color: aqua; */
  color: black;
}