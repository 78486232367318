@media (max-width: 768px){
  .sli{
    top: -250px;
  }
}
@media (max-width: 480px){
  .imcl{
   left: -50px;
  }
  .sli{
    right: -20px;
  }
}