.item img{
  /* border:1px solid #e2e2e2; */
  padding: 5px;
  border-radius:5px;
  margin:5px;
}
.carousel-inner.vertical {
  height: 100%; /*Note: set specific height here if not, there will be some issues with IE browser*/
}
.carousel-inner.vertical > .item {
  -webkit-transition: .6s ease-in-out top;
  -o-transition: .6s ease-in-out top;
  transition: .6s ease-in-out top;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
  .carousel-inner.vertical > .item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
  }
  .carousel-inner.vertical > .item.next,
  .carousel-inner.vertical > .item.active.right {
    -webkit-transform: translate3d(0, 33.33%, 0);
    transform: translate3d(0, 33.33%, 0);
    top: 0;
  }
  .carousel-inner.vertical > .item.prev,
  .carousel-inner.vertical > .item.active.left {
    -webkit-transform: translate3d(0, -33.33%, 0);
    transform: translate3d(0, -33.33%, 0);
    top: 0;
  }
  .carousel-inner.vertical > .item.next.left,
  .carousel-inner.vertical > .item.prev.right,
  .carousel-inner.vertical > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    top: 0;
  }
}

.carousel-inner.vertical > .active {
  top: 0;
}
.carousel-inner.vertical > .next,
.carousel-inner.vertical > .prev {
  top: 0;
  height: 100%;
  width: auto;
}
.carousel-inner.vertical > .next {
  left: 0;
  top: 33.33%;
  right:0;
}
.carousel-inner.vertical > .prev {
  left: 0;
  top: -33.33%;
  right:0;
}
.carousel-inner.vertical > .next.left,
.carousel-inner.vertical > .prev.right {
  top: 0;
}
.carousel-inner.vertical > .active.left {
  left: 0;
  top: -33.33%;
  right:0;
}
.carousel-inner.vertical > .active.right {
  left: 0;
  top: 33.33%;
  right:0;
}

#carousel-pager .carousel-control.left {
    bottom: initial;
    width: 100%;
}
#carousel-pager .carousel-control.right {
    top: initial;
    width: 100%;
}

.font-bold{
  font-size: 40px;
  font-weight: bold ;
  color: #404040;
  font-family: 'Gotham',Bold; 
}
.font-medium{
  font-size: 14px;
  font-weight: 500 ;
  color: #656565;
  font-family: 'Gotham', Medium;
}
.font-ullight{
  font-family: 'Gotham',Book;
  color: '#9B9B9B';
}
.font-light{
  /* font-family: 'Gotham Black', sans-serif; */
  font-size: 50px;
  font-weight: bold ;
  color: #404040;
  /* font-family: 'Gotham Black', sans-serif; */
  /* font-family: 'Gotham Light', sans-serif; */
  /* font-family: 'Gotham Medium', Arial, Helvetica, sans-serif; */
  /* font-family: 'Gotham XLight', sans-serif; */
  /* font-family: 'Gotham Book', sans-serif; */
  font-family: 'Gotham Bold', sans-serif; 
}

@media(max-width: 768px){
  .clpi{
    top: 5rem
  }
}
@media(max-width: 768px){
  .cpi{
    top: -8rem
  }
}
@media(max-width: 481px){
  .clpi{
    left: -8rem
  }
  .cpi{
    right: -3rem
  }
}