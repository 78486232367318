@media (max-width: 480px){
  .btn{
    left: -20px;
  }
}

@media (max-width: 480px){
  .pcol{
    left: -50px;
  }
}

@media (max-width: 768px){
  .btn{
    left: -20px;
  }
}

@media (max-width: 768px){
  .pcol{
    left: -50px;
  }
}