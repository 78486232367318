.carousel-indicators li {
  width: 64px;
  height: 7px;
  top: 4rem;
  right: 16rem;
  left: -19rem;
  /* border-radius: 100%; */
}
.carousel-indicators .active{
  background-color: #F28F31;
}

@media(max-width: 480px){
  .carousel-indicators li {
    left: -4rem;
  }
}
@media(min-width: 768px){
  .carousel-indicators li {
    left: -15rem;
  }
}