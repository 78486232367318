@media(max-width: 480px){
  .pcol{
    left: -40px;
  }
  .pcoll{
    margin-left: 50px;
  }
}
@media(max-width: 768px){
  .pcol{
    left: -60px;
  }
  .pcoll{
    left: -40px;
  }
}