.fullimage {
  background-image: url("../../assets/images/Slider.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* html, body {
  overflow: hidden;
} */

/* ul {
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

li {
  display: inline-block;
  margin: 10px;
} */

@media screen and (max-width: 304500px){
  .booknow{
    margin-top:8px;
  }
  .apbtn{
    left: 35px;
    top: 40rem;

  }
}