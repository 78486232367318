.nav-link{
  font-size: 20px;
  /* padding: 5px; */
  /* border-bottom: 5px solid #F28F31; */
  color:  #656565;
  
}
.selected{
  font-size: 20px;
  /* padding: 5px; */
  /* border-bottom: 5px solid #F28F31; */
  color:  #656565;
  /* padding-left:20px;
  padding-right:10px; */
}

.navbar-nav > li{
  color:  #656565;
  padding-left:20px;
  padding-right:10px;
  /* border-bottom: 5px solid black; */
}

/* .navbar {
  border-bottom: 1px solid orangered;
} */
.nav-link {
  font-weight: medium;
  font-size: 20px;
  /* text-transform: uppercase; */
  text-decoration: none;
  color: #656565;
  /* padding: 20px 0px; */
  /* margin: 0px 20px; */
  display: inline-block;
  position: relative;
  opacity: 0.75;
}

.nav-link:hover {
  opacity: 1;
}

.nav-link::before {
  transition: 300ms;
  height: 5px;
  content: "";
  position: absolute;
  background-color: #F28F31;
}
.nav-link-grow-up::before {
  height: 0%;
  width: 100%;
  bottom: 0px;
}

.nav-link-grow-up:hover::before {
  height: 5px;
}