.mt-100 {
  margin-top: 150px
}

.filter-group {
  border-bottom: 1px solid #e4e4e4
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.37rem
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1)
}

.filter-group .card-header {
  border-bottom: 0
}

.icon-control {
  margin-top: 6px;
  float: right;
  font-size: 80%;
}

.list-menu {
  list-style: none;
  margin: 0;
  padding-left: 0;

}

.list-menu a {
  /* color: #343a40 */
  font-size: 20px;
  color: #656565;
}

a {
  text-decoration: none !important;
  background-color: transparent
}

.checkbox-btn {
  position: relative
}

.checkbox-btn input {
  position: absolute;
  z-index: -1;
  opacity: 0
}

.checkbox-btn input:checked~.btn {
  border-color: orange;
  background-color: orange;
  color: #fff
}

.btn-light {
  display: inline-block;
  font-weight: 600;
  color: #343a40;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #eee;
  border: 1px solid #eee;
  padding: 0.45rem 0.85rem;
  font-size: 10px;
  line-height: 1.5;
  border-radius: 0.37rem
}

.btn-light:hover {
  background-color: #fff;
  border-color: #989898
}