.download {
  width: 170px;
  height: 65px;
  background: white;
  float: left;
  border-radius: 5px;
  position: relative;
  color: black;
  cursor: pointer;
  border: 1px solid #fff;
}

.download > .fa {
  color: black;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.df,
.dfn {
  position: absolute;
  left: 50px;
}

.df {
  color: #a5a5a5;
  top: 10px;
  font-size: .68em;
}

.dfn {
  top: 25px;
  font-size: 1.08em;
}

.download {
  box-shadow: 0px 2px 6px rgb(231, 231, 231)
}
li{
  list-style: none;
}
@media screen and (max-width: 304500px){
  .download{
    /* top: 3.5rem; */
    right: 0.5rem;
    /* margin: -20px; */
  }
}